import React, { createContext, useContext, useEffect, useState } from 'react'
import { Web3Context } from './Web3Context'
import { useStaticQuery, graphql } from 'gatsby'

export const Gen2Context = createContext(null)

export const Gen2Provider = ({ children }) => {

	const { account, chainId, web3 } = useContext(Web3Context) || {}
	const [contract, _setContract] = useState(null)
	const [adoptedCubs, setAdoptedCubs] = useState(0)
	const [adoptionPrice, setAdoptionPrice] = useState(-1)
	const [maxAdoptions, setMaxAdoptions] = useState(2500)
	const [maxQuantity, setMaxQuantity] = useState(10)

	async function setContract(_contract: any) {
		if (_contract !== null) {
			let adoptionPriceTx = _contract.methods.ADOPTION_PRICE().call()
			let remainingTx = _contract.methods.remainingAdoptions().call()
			let totalTx = _contract.methods.TOTAL_ADOPTIONS().call()
			let quantityTx = _contract.methods.MAX_ADOPT_QUANTITY().call()
			let [adoptionPrice, remaining, total, quantity] = await Promise.all([adoptionPriceTx, remainingTx, totalTx, quantityTx])
			setAdoptionPrice(adoptionPrice)
			setAdoptedCubs(total-remaining)
			setMaxAdoptions(total)
			setMaxQuantity(quantity)
		}
		_setContract(_contract)
	}

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { sourceInstanceName: { eq: "contract-data" }, name: { eq: "TwoBitCubs" } }) {
				nodes {
					relativeDirectory
					name
					fields { contents }
				}
			}
		}
	`)

	async function adoptCubs(quantity: number) {
		var price = adoptionPrice * quantity
		console.log(`Adopting ${quantity} cubs for ${web3.utils.fromWei(price.toString(), "ether")} ETH...`)
		var parameters = { value: price, gasLimit: 250000 * quantity, from: account, type: "0x2" }
		await contract.methods.adoptCub(quantity).send(parameters)
	}

	async function growCub(tokenId: number) {
		console.log(`Growing cub ${tokenId}! Wait for transaction and auto-refresh`)
		var parameters = { gasLimit: 60000, from: account, type: "0x2" }
		await contract.methods.wakeCub(tokenId).send(parameters)
	}

	async function mateBears(firstParentId: number, secondParentId: number) {
		// gasLimit needs to be high enough to account for triplets
		var parameters = { gasLimit: "600000", from: account, type: "0x2" }
		console.log(`Mating bears ${firstParentId} & ${secondParentId}! Wait for transaction and auto-refresh`)
		await contract.methods.mateBears(firstParentId, secondParentId).send(parameters)
	}

	async function loadContract() {
		if (chainId === null || chainId == 0 || web3 === null || web3 === undefined || web3.eth === null || web3.eth === undefined) {
			setContract(null)
			return
		}
		try {
			for (const node of data.allFile.nodes) {
				if (parseInt(node.relativeDirectory) == chainId) {
					const json = JSON.parse(node.fields.contents)
					setContract(new web3.eth.Contract(json.abi, json.address))
					return
				}
			}
			console.warn(`Could not find TwoBitCubs contract for chain ${chainId}`)
			setContract(null)
		} catch (error) {
			console.error(error)
			setContract(null)
		}
	}

	useEffect(() => {
		loadContract()
	}, [chainId, web3])

	return (
		<Gen2Context.Provider value={{contract, adoptCubs, adoptedCubs, growCub, mateBears, maxAdoptions, maxQuantity}}>
			{children}
		</Gen2Context.Provider>
	)
}
