import Layout from '../components/layout'
import React from 'react'
import { Gen2Provider } from '../contexts/Gen2Context'
import GrowCub from '../components/grow-cub'

export default function IndexPage() {

	return (
        <Layout prompt="Select The Cub You Want to Grow Up">
			<Gen2Provider>
				<GrowCub/>
			</Gen2Provider>
        </Layout>
    )
}
