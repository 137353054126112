import React, { useContext, useEffect, useState } from 'react'
import { Gen2Context } from '../contexts/Gen2Context'
import { Web3Context } from '../contexts/Web3Context'

export type ImageAttributes = {
	tokenId: number,
	name: string,
	image: string
}

export default function OwnedCubs({onUserSelection}) {

	const { contract } = useContext(Gen2Context) || {}
	const { account } = useContext(Web3Context) || {}

	const [images, setImages] = useState<ImageAttributes[]|null>(null)

	function deconstructTokenURI(uri: string, tokenId: string): ImageAttributes {
		let split = uri.substring(uri.indexOf(',') + 1)
		let decoded = Buffer.from(split, 'base64').toString()
		let metadata = JSON.parse(decoded)

		return {
			tokenId: parseInt(tokenId),
			name: metadata.name as string,
			image: metadata.image as string
		}
	}

	async function enumerateOwnedAndUnwokeCubs() {
		console.log(`Enumerating cubs for ${account}`)
		let numberOfOwned = await contract.methods.balanceOf(account).call()
		if (numberOfOwned == 0) {
			setImages([])
			return
		}
		let added = 0
		let counted = 0
		let checkLast = (addedValue: number) => {
			if (++counted == numberOfOwned) {
				if (addedValue == 0) {
					console.log(` - setting blank images`)
					setImages([])
				} else {
					console.log(` - finished`)
				}
			}
		}
		for (var i = 0; i < numberOfOwned; i++) {
			contract.methods.tokenOfOwnerByIndex(account, i).call(async function(error: any, tokenId: string) {
				try {
					if (error) {
						console.error(error)
					} else {
						var traits = await contract.methods.traitsV1(tokenId).call()
						if (traits.age > 0) {
							console.log(`Skipping woke cub: ${tokenId}`)
						} else {
							var uri = await contract.methods.tokenURI(tokenId).call()
							const decodedTokenUri = deconstructTokenURI(uri, tokenId);
							if (added++ == 0) {
								console.log(`Setting first item: ${added}`)
								setImages([decodedTokenUri])
							} else {
								setImages(old => {
									var result = [...old]
									const sortedIndex = (array: any[], value: any) => {
										var low = 0, high = array.length
										while (low < high) {
											var mid = (low + high) >>> 1
											if (array[mid].tokenId === value) return mid
											if (array[mid].tokenId < value) low = mid + 1
											else high = mid
										}
										return low
									}
									const insert = sortedIndex(result, decodedTokenUri.tokenId)
									if (insert < result.length && result[insert].tokenId === decodedTokenUri.tokenId) {
										result.splice(insert, 1, decodedTokenUri)
									} else {
										result.splice(insert, 0, decodedTokenUri)
									}
									return result
								})
							}
						}
					}
				} catch (error) {
					console.error(error)
				}
				checkLast(added)
			})
		}
	}

	useEffect(() => {
		if (contract === null || account === null) {
			setImages(null)
		} else {
			enumerateOwnedAndUnwokeCubs()
		}
	}, [contract, account])

	return (
		<>
			{images == null ? <p>Searching your cubs...</p> :
				images.length == 0 ? <p>This wallet has no eligible cubs</p> :
				<ul>
					{images.map((cub) =>
						<li key={cub.tokenId} onClick={() => onUserSelection(cub)}>
							<img src={cub.image} alt="Bear"/>
							<p className="selected">{cub.name}</p>
						</li>
					)}
				</ul>
			}
		</>
	)
}
