import React from 'react'
import * as styles from './modal.module.css'

export default function Modal({title, dismissText = "Cancel", onUserClose, children}) {

	return (
		<div className={styles.modalOverlay}>
			<div className={styles.modal}>
				<div className={styles.topBar}>
					{title}
					<button onClick={() => onUserClose()}>{dismissText}</button>
				</div>
				<div className={styles.wrapper}>
					{children}
				</div>
			</div>
		</div>
	)
}
