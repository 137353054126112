import React, { useContext, useEffect, useState } from 'react'
import { Gen2Context } from '../contexts/Gen2Context'
import { Web3Context } from '../contexts/Web3Context'
import { navigate } from 'gatsby'
import Modal from './modal'
import OwnedCubs, { ImageAttributes } from './owned-cubs'

export default function GrowCub() {

	const { contract, growCub } = useContext(Gen2Context) || {}
	const { account, chainId } = useContext(Web3Context) || {}

	const [controlsDisabled, setControlsDisabled] = useState(false)
	const [selectedCub, setSelectedCub] = useState<ImageAttributes|null>(null)
	const [selectDisabled, setSelectDisabled] = useState(false)
	const [showModal, setShowModal] = useState(false)

	function closeModal() {
		setShowModal(false)
	}

	async function growClicked() {
		setControlsDisabled(true)
		try {
			await growCub(selectedCub.tokenId)
			navigate("/grow-complete/", { replace: true })
		} catch (error) {
			// TODO: Notify the user with a Toast message
			if (error.code == 4001 || error.code == 4100) {
				console.log(`Adoption not authorized by user`)
			} else {
				console.error(error)
			}
		}
		setControlsDisabled(false)
	}

	function startSelection() {
		setShowModal(true)
	}

	function completeSelection(selectedCub: ImageAttributes) {
		console.log(`Selected cub ${selectedCub.tokenId}`)
		setSelectedCub(selectedCub)
		setShowModal(false)
	}

	function contractsUnavailable() {
		return contract === null || contract === undefined
	}

	useEffect(() => {
		setSelectDisabled(contractsUnavailable())
	}, [contract])

	useEffect(() => {
		setControlsDisabled(contractsUnavailable() || selectedCub === null)
	}, [contract, selectedCub])

	useEffect(() => {
		setSelectedCub(null)
	}, [account, chainId])

	return (
		<>
			<ul>
				<li onClick={selectDisabled ? null : startSelection}>
					<img src={selectedCub?.image ?? "/img/cub-outline.png"} alt="Cub"/>
					{selectedCub == null ?
						<p>Select Cub</p> : <p className="selected">{selectedCub.name}</p>
					}
				</li>
			</ul>
			<button className="initiate" disabled={controlsDisabled} onClick={growClicked}><h4>Initiate Grow Process</h4></button>
			{showModal ? <Modal title="Select the Cub You Want to Grow Up" onUserClose={closeModal}>
				<OwnedCubs onUserSelection={completeSelection}/>
			</Modal> : <></>}
		</>
	)
}
